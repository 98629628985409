
import { defineComponent } from "vue";
import { Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "approve-policy-document-component",
  components: { Field, Form },
  props: {
    publicId: String,
  },
  data() {
    return {
      folios: [],
      folioItem: this.publicId,
    };
  },
  setup() {
    // const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const documentUploadValidator = Yup.object().shape({
      certificate: Yup.mixed()
        .required()
        .label("Policy certificate"),
    });

    const submitForm = (values) => {
      const submitButton1 = document.getElementById(
        values["folioItem"]
      ) as HTMLElement;

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1?.removeAttribute("data-kt-indicator");

          // Submit data
          if (JwtService.getToken()) {
            const formData = new FormData();
            formData.append("folioItem", values["folioItem"]);
            formData.append(
              "certificate",
              values["certificate"][values["folioIndex"]] as string | Blob
            );
            // new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `underwriter/${values["folioItem"]}/certificate`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                // resetForm();
                // Re-route to policy certificate upload page
                router.push({
                  name: "underwriter-dashboard",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };
    return { documentUploadValidator, submitForm };
  },
  created() {
    // Get underwriters clients
    this.getFolios();

    //Set page title
    document.title = "Approve Policy Document | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    // Get list of folios without certificate approval
    getFolios() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/certificate/document-uploads")
          .then(({ data }) => {
            // Assign data to variables
            this.folios = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    approvePolicyDocument(publicId) {
      const submitButton1 = document.getElementById(publicId);
      this.folioItem = publicId;

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");
        const formData = new FormData(document.forms[0]);

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(
            `${variables.POLICIES_ROUTE}/document/${publicId}/approve`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              variables.toastAlert(
                "Policy document approved successfully.",
                "success"
              );
              this.getFolios();
            })
            .catch(function(error) {
              submitButton1.removeAttribute("data-kt-indicator");
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },

    previewCertificate(publicId) {
      const submitButton1 = document.getElementById(`preview-${publicId}`);
      const formData = new FormData(document.forms[0]);

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        // Delete cart item
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.post(
            `${variables.POLICIES_ROUTE}/document/${publicId}/download`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");

              const mimeType =
                response.data.type == "application/pdf"
                  ? "application/pdf"
                  : "image/jpeg";
              const mimeTypeExtension =
                response.data.type == "application/pdf" ? "pdf" : "jpeg";

              const blob = new Blob([response.data], {
                type: mimeType,
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = `preview-${publicId}.${mimeTypeExtension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
              // submitButton1.removeAttribute("data-kt-indicator");
            });
        }
      }
    },
  },
});
