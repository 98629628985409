
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import ApproveDocument from "@/components/reusable/policy-information/documents-uploads/ApproveDocument.vue";

export default defineComponent({
  name: "admin-approve-policy-document",
  components: { ApproveDocument },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Approve Document", [
        "Administrator",
        "Policies",
      ]);
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title =
      "Administrator: Approve Policy Document | " + process.env.VUE_APP_TITLE;
  },
});
